.course-details-component {
    background-color: $light02;
    .head {
        margin-bottom: -50px;
        padding-bottom: 35px;
    }
}
.course-details-header {
    min-height: unset !important;
    // background: #6047ec;

    // h1 {
    //     font-family: Montserrat;
    //     font-style: normal;
    //     font-weight: bold;
    //     font-size: 26px;
    // }
    .batch-name {
        font-weight: 600;
        font-size: 48px;
        color: #000;
        font-family: "Fira Sans";
    }
    .paragraph {
        font-family: "Poppins", "Hind Siliguri", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #727272;
    }
    .label {
        font-family: "Fira Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #263238;
        margin-bottom: 20px;
    }
    .instructor {
        img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 5px;
        }
        h6 {
            margin: 2px;
            font-family: "Poppins", "Hind Siliguri", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #000;
        }
        p {
            margin: 0;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            color: #aeaeae;
        }
    }
    .authors-wrapper {
        border: 1px solid #d5dde3;
        border-radius: 8px;
        padding: 20px 20px 12px;
    }
}
.course-details-section {
    margin-right: 15px;
    .description {
        h3 {
            font-family: "Fira Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 25px;
            color: #263238;
        }
    }
    .course-about {
        border: 1px solid #d5dde3;
        border-radius: 8px;
        padding: 25px;
        p {
            font-family: "Poppins", "Hind Siliguri", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            color: #727272;
        }
    }

    h3 {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        color: #263238;
        @media (max-width: 768px) {
            font-size: 17px;
        }
    }
    h4 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
    }

    h1.rating {
        font-size: 100px;
        font-weight: 600;
        line-height: 76px;
    }

    .preview-card-container {
        top: -280px;
    }
    .text-yellow {
        color: #efc40f;
    }
    .complete-course-button {
        display: none !important;
    }
    .custom-progressbar-component {
        width: 98%;
        margin-bottom: 15px;
        border-radius: 23px;
        .progress {
            height: 10px;
        }
        .progress-bar {
            background: #ff136f;
            border-radius: 23px;
            font-size: 0;
        }
    }
    .single-review {
        .meta {
            p {
                margin: 0;
            }
            .rating-point {
                color: #efc40f;
            }
        }
        .date p {
            font-size: 13.5351px;
            color: #6b6b6b;
        }
        .comment p {
            margin-top: 20px;
            font-size: 14px;
            line-height: 22px;
            color: #454545;
        }
        .student-name {
            font-weight: 500;
            font-size: 16px;
            line-height: 35px;
            color: #000000;
        }

        .avatar {
            width: 55px;
            height: 55px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 10px;
        }
    }
}

@media (max-width: 768px) {
    h1 {
        font-size: 1.3em;
    }
    .review-and-feedback {
        .m-sm-reset {
            margin: 0 !important;
        }
    }
}
@media (max-width: 991px) {
    .preview-card-container {
        position: static;
        top: unset;
    }
}
