.manual-bkash-payment-instructions {
    background-color: $white;
    border-radius: 10px;
    color: #000000;

    h4 {
        font-family: Hind Siliguri;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;

        .highlight {
            font-family: Hind Siliguri;
            font-weight: 700;
            color: $black;
        }
    }
    h6 {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
    }
    svg {
        color: $payment-chek-item;
        margin-right: 10px;
        margin-top: 10px;
    }
    li {
        display: flex;
        font-weight: 300;
        font-size: 16px;
        line-height: 32px;
        align-items: start;
    }
}
