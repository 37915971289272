.course-content-comonent {
	.custom-accordion-component {
		margin-bottom: 5px;
		border: 1px solid #e5e5e5;
		// box-shadow: 0 1px 1px rgba(0,0,0,.04);
		border-radius: unset;
		// box-shadow: unset !important;
	}
	.MuiIconButton-root {
		font-size: 15px;
	}
	.MuiAccordionDetails-root {
		min-height: 63px;
	}
	h5 {
		font-size: 15px;
	}
	.MuiPaper-elevation1 {
		border-radius: unset !important;
	}
	.module-course-name {
		width: 1000px;
		display: block;
		.child-course {
			background-color: #ffd8e7;
			color: $secondary;
			padding: 5px 20px;
			border-radius: 26px;
			float: right;
		}
	}
	@media (max-width: 1500px) {
		.module-course-name {
			width: 800px;
		}
	}
	@media (max-width: 1300px) {
		.module-course-name {
			width: 600px;
		}
	}
	@media (max-width: 1100px) {
		.module-course-name {
			width: 500px;
		}
	}
	@media (max-width: 700px) {
		.module-course-name {
			width: 300px;
		}
	}
	@media (max-width: 500px) {
		.module-course-name {
			display: flex;
			align-items: center;
			.child-course {
				margin-left: 10px;
			}
		}
	}
}

.ReactModal__Overlay--after-open {
	z-index: 1200;
	background-color: #2b2828bf !important;
}

.individual-examiner {
	.stats {
		display: flex !important;
		padding: 0 25%;
	}
}
