.frequently-asked-component {
    .sub-milestone-title {
        color: #9fa5a5;
        font-size: 12px;
    }

    #overlay-bg {
        position: absolute;
        bottom: 0;
    }

    .MuiAccordionSummary-content.Mui-expanded {
        margin: 10px 0;
    }
    .MuiButtonBase-root {
        border-radius: 10px;
    }

    .MuiPaper-elevation1 {
        box-shadow: unset;
        border-radius: 10px !important;
    }
    .frequently-asked-main {
        background-image: $light;
    }
}
