.semester-summary-page {
    .gray-title {
        font-size: 24px;
        color: $gray-dark;
        font-family: Poppins;
        font-weight: 600;
    }
    .overlay-bg {
        background-color: $blue-overlay;
        padding: 18px 40px;
        border-radius: 4px;
        margin: 15px 0;
        h3 {
            font-size: 18px;
            font-weight: 600;
            font-family: Poppins;
            color: $gray-dark;
            .text-button {
                color: $primary3;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
    .semester-info-card {
        border: 1px solid $gray6;
        border-radius: 8px;
        .gray-title {
            border-bottom: 1px solid $gray6;
        }
    }
    .course-status {
        border-radius: 140px;
        padding: 8px 16px;
        color: #1dc39a;
        background: #e3fbf5;
        &.inprogress {
            color: $primary3;
            background: #e4effd;
        }
        &.upcoming {
            color: #e34a30;
            background: #fcebe8;
        }
    }
    @media (max-width: 600px) {
        .gray-title {
            font-size: 18px;
        }
        .overlay-bg {
            padding: 10px 15px;
            .grade-button {
                padding: 10px 20px;
                font-size: 14px;
            }
        }
    }
    @media (max-width: 400px) {
        .gray-title {
            font-size: 14px;
        }
        .overlay-bg {
            padding: 10px;
            .grade-button {
                padding: 10px;
                font-size: 12px;
            }
        }
    }
}
