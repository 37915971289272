.profile-discussion-area-component {
    .profile-discussion-textarea {
        background: #F9F9F9;
        border: 1px solid #DEDEDE;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 14px;
        padding: 15px;
    }

    .profile-discussion-textarea:focus-visible {
        border: unset !important;
    }

    .discussion-send-button {
        width: 75px;
        height: 28px;
        background: #5E24F9;
        border-radius: 4px;
        color: #fff;
        border: unset;

    }
}