.custom-numbaric-progressbar-component {

    .main-progress-numbaric {
        width: 100%;
    }
   
    
}

// .progress-div .progress {
//     height: 10px !important;
// }
