.enroll-card {
    img.lazy-image {
        border-radius: 5px;
    }
    border-radius: 16px;
    font-weight: 300;
    background-color: white;
    .rating {
        .checked {
            color: #ffae14;
        }
    }
    .status,
    .update-status {
        font-size: 14px;
        i {
            margin-right: 5px;
        }
    }
    .price {
        color: #f77211;
    }
    .key-criterias {
        li {
            margin-bottom: 7px;
            i {
                color: #f77211;
            }
        }
    }
    .francy-btn {
        width: 100%;
        margin: 15px 0;
        display: block;
    }
}

.preview-card {
    font-family: "Poppins", sans-serif;
    padding-bottom: 30px !important;
    max-width: 580px;
    margin: 0 auto;
    .preview {
        img.img-fluid.w-100 {
            border-radius: 5px;
        }
        position: relative;
        .play-icon {
            width: 110px;
            height: 110px;
            position: absolute;
            top: calc(50% - 55px);
            left: calc(50% - 55px);
            cursor: pointer;
        }
    }
    h4 {
        margin-bottom: 0;
        margin-top: 10px;
        font-size: 20px;
        color: #000;
        font-weight: 600;
    }
    .status p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #636363;
    }
    .price {
        font-size: 36px;
        line-height: 54px;
        color: #303030;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 26px;
            line-height: 38px;
        }
    }
    .btn {
        display: block;
        width: 100%;
        padding: 12px;
        margin-top: 7px;
        height: 50px;
    }
    .btn-outline {
        border: 1px solid #ff136f;
        color: #ff136f;
        background-color: #fff !important;
        border-radius: 41px;
    }
    .calender {
        background: linear-gradient(335.64deg, #ff589b -19.22%, #ff136f 118.87%) !important;
        border-radius: 52px;
        padding: 8px;
        strong {
            font-weight: bold;
        }
        @media (max-width: 768px) {
            padding: 0;
            img {
                transform: scale(0.7);
            }
            p {
                font-size: 0.9em;
            }
        }
    }
    .count {
        // flex-wrap: nowrap;

        .count-item {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        @media (max-width: 550px) {
            .count-item {
                flex-direction: column;
            }
        }
    }
    .count > div {
        margin: 2px;
        flex: auto;
        img {
            width: 24px;
            height: 24px;
            margin-right: 5px;
        }
        flex-wrap: wrap;
        display: flex;
        background: #ffffff;
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.05);

        align-items: center;
        border-radius: 57.2653px;
        p {
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            color: #6c6c6c;
        }
    }
    .timer {
        background: linear-gradient(86.89deg, #343c97 -111.91%, #31c4f3 79.41%);
        border-radius: 10px;
        padding: 14px 10px;
        color: #ffffff;
        font-family: Poppins;
        font-weight: 500;
        font-size: 15px;
    }
    .countdown {
        span {
            font-weight: 700;
            background: #3b27af;
            border-radius: 7.7193px;
            margin-left: 5px;
            padding: 8px 9px;
            font-family: Poppins;
            font-weight: bold;
            font-size: 15px;
        }
    }
    @media (max-width: 425px) {
        .status p {
            font-size: 13px !important;
        }
        .play-icon {
            width: 90px;
            height: 90px;
            top: calc(50% - 45px) !important;
            left: calc(50% - 45px) !important;
        }
    }
    @media (min-width: 426px) and (max-width: 992px) {
        .count > div {
            width: 100%;
            margin-right: 2px;
        }
    }
    @media (min-width: 992px) and (max-width: 1199px) {
        .timer {
            font-size: 13px;
        }
        .countdown span {
            padding: 5px !important;
            font-size: 10px;
            margin: 2px;
        }
        .count > div {
            width: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
            margin-right: 2px;
            border-radius: 10px;
        }
    }
}

@import "node_modules/react-modal-video/scss/modal-video.scss";
