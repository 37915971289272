.checkout-page {
    .checkout-form {
        padding-top: 6px;
        .payment-form-shadow {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
            border-radius: 10px;
        }
        .payment-title {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            color: $light-black;
        }
        .payment-promo-code-text {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: $mid-black;
        }
        .payment-btn {
            background: $primary2;
            border-radius: 5px;
            width: 250px;
            color: $white;
            font-size: 20px;
          }
          .deactive-btn{
            background: #A2A2A2;
            width: 100%;
            border-radius: 5px;
            color: $white;
            font-size: 22px;
          }
        .form-control {
            border-radius: 5px;
            padding: 20px 20px;
        }
        .payment-option{
            margin-left: 3px;
        }
      
    }

    label {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
    // .promo-code-box {
    //     position: relative;
    // }
    .lead {
        font-weight: 600;
        font-size: 17px;
        line-height: 27px;
    }
 
    .policy-check {
        label {
            font-weight: 300;
            color: #6b6b6b;
        }
    }
    h3 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #161616;
    }
    @media (max-width: 425px) {
        .form-control {
            padding: 10px 15px;
        }
        .lead {
            font-size: 15px;
        }
    }
}
