.text-editor-format {
  p,
  ul {
    margin-top: 0;
    margin-bottom: 0;
  }
  p,
  li,
  a {
    font-size: 20px;
  }
}
.ReactModal__Body--open {
  // .enroll-now-tab {
  //   .nav-link:active {
  //     background-color: unset !important;
  //     border-color: #c4c9d0 !important;
  //  }
  // }
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}
