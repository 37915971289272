.enrolled-course {
    .shadow {
        box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.07) !important;
    }
    .custom-progressbar-component {
        width: 100%;
        margin-right: 5px;
    }

    .price-text {
        color: $light-sky-blue;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
    }

    .batch-id-text {
        color: $raven;
    }

    .card {
        border-radius: 10px;
        color: initial;
        width: fit-content;
        .card-body {
            .thumb-section {
                .card-img-top {
                    border-radius: 8px !important;
                    max-width: 200px !important;
                    min-width: 126px !important;
                }
            }
            .content-section {
                .card-title {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    color: $black;
                }
                .card-author {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    color: $black;
                }

                .progress-text {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.52);
                }

                .new-course {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 10px;
                    color: #483535;
                    .new-course-date {
                        color: #545ae8 !important;
                    }
                }

                .new-course-date-label {
                    font-size: 10px;
                    line-height: 10px;
                    color: #483535;
                }
            }

            .contine-course-btn {
                outline: none;
                border: 0;
                background: linear-gradient(
                    344.66deg,
                    #343c97 -80.56%,
                    #3372bb -4.55%,
                    #329eda 68.85%,
                    #31baec 123.9%,
                    #31c4f3 157.97%
                );
                border-radius: 52px;
                padding: 10px 0px;
                font-size: 15px;
                height: 40px;
            }
            .enrollment-close-btn {
                outline: none;
                border: 0;
                background: linear-gradient(86.89deg, #343c97 -111.91%, #31c4f3 79.41%);
                border-radius: 52px;
                padding: 10px 0px;
                font-size: 20px;
            }
            .add-to-cart-btn {
                border-radius: 100rem;
                padding: 1rem;
                font-family: "Poppins";
                font-size: 1rem;
                padding: 10px 0px;
                color: #02b8f5;
                box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
                border: solid 2px transparent;
                background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
                    linear-gradient(101deg, #343c97, #31c4f3);
                background-origin: border-box;
                background-clip: content-box, border-box;
                box-shadow: 2px 1000px 1px #fff inset;
            }

            .footer {
                .fee {
                    color: #ff136f;
                    font-weight: bold;
                    font-size: 24px;
                }
                p {
                    font-weight: 600;
                    font-size: 12px;
                }
                p.text-secondary {
                    font-size: 12px;
                    font-weight: normal;
                    color: #363636 !important;
                }
            }
        }
    }
    a:hover {
        text-decoration: none;
    }

    @media screen and (max-width: 320px) and (min-width: 0px) {
        .card-title {
            margin-top: 10px;
            font-size: 8px;
        }
    }

    .availabe-course-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: #353536;
    }
}
