.conceptual-session-page {  
  .course-content-component {
    box-shadow: 0px 4px 16px 0px #0000000d;
    // .MuiIconButton-edgeEnd {
    //   height: 28px;
    //   width: 28px;
    //   color: #407bff;
    //   background: $white;
    //   font-size: 25px;
    //   margin-right: 0px;
    //   border-radius: 3px;
    //   svg {
    //     font-size: 18px;
    //   }
    // }
    .course-content-list{
      background: red !important;
    }
    
  }

  .MuiAccordion-root {
    height: 100%;
    max-height: 454px;
    overflow-y: auto;
  }

  // .MuiPaper-root{
  //   background :#407bff;
  // }

  .MuiAccordionDetails-root {
    border-radius: 50px !important;
  }

  .module-component {
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px #0000000d;
    border-radius: 4px;
    border-radius: 0 !important;
    padding: 0 0 10px 0 !important;
    margin: 0 !important;

    .MuiIconButton-edgeEnd {
      background: unset;
      color: $black;
      font-size: 10px !important;
      bottom: 0;
    }

    .unit-component {
      button {
        padding: 10px 7px !important;
        box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
      }
      .un-active-item {
        padding: 12px 0;
        align-items: center;

        .un-active-img {
          margin-left: 15px;
        }
      }
    }
    .milestone-title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 123.5%;
      color: #1c2729;
    }
  }

  .sub-milestone-title {
    font-size: 12px !important;
    padding-top: 4px;
  }

  .accordion-custom-details {
    padding: 10px 0 0 0;
  }
}

.module-active-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.75);
}
.module-un-active-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
}
