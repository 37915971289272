.tooltip-wrapper{
    position:relative;
  }
  .tooltip-wrapper:hover .tooltip-content{
    display:block;
  }
  .tooltip-wrapper .tooltip-content{
    position:absolute;
    min-width:100px;
    white-space:nowrap;
    background:white;
    padding:10px;
    border-radius:5px;
    text-align:center;
    display:none;
    box-shadow:0 0 10px rgba(0,0,0,.1);
  }
  .tooltip-wrapper .tooltip-content::after{
    content:'';
    position:absolute;
  }
  .tooltip-content.tooltip-top{
    top:-6px;
    left:50%;
    transform:translate(-50%,-100%);
  }
  .tooltip-content.tooltip-right{
    bottom:50%;
    right:-6px;
    transform:translate(100%,50%);
  }
  .tooltip-content.tooltip-bottom{
    bottom:-6px;
    left:50%;
    transform:translate(-50%,100%);
  }
  .tooltip-content.tooltip-left{
    bottom:50%;
    left:-6px;
    transform:translate(-100%,50%);
  }
  .tooltip-content.tooltip-top::after{
    border-top:6px solid white;
    border-left:7px solid transparent;
    border-right:7px solid transparent;
    bottom:-5px;
    left:50%;
    transform:translateX(-50%)
  }
  .tooltip-content.tooltip-right::after{
    border-top:7px solid transparent;
    border-bottom:7px solid transparent;
    border-right:6px solid white;
    top:50%;
    left:-5px;
    transform:translateY(-50%)
  }
  .tooltip-content.tooltip-bottom::after{
    border-bottom:6px solid white;
    border-left:7px solid transparent;
    border-right:7px solid transparent;
    top:-5px;
    left:50%;
    transform:translateX(-50%)
  }
  .tooltip-content.tooltip-left::after{
    border-top:7px solid transparent;
    border-bottom:7px solid transparent;
    border-left:6px solid white;
    top:50%;
    right:-5px;
    transform:translateY(-50%)
  }