.add-job-details{
    border-radius: 10px;
    .ql-editor{
        height: 300px;
    }
    .selection-btn{
        background: #5E24F9;
        font-weight: 600;
        color: #FFFF;
        border-radius: 5px;
        width: 110px;
    }
    .dropdown-btn{
        background: #5E24F9;
        font-weight: 600;
        color: #FFFF;
    }
    
}