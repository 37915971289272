.form-component {
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height, or 117% */

    text-align: center;

    color: #122e5b;
  }
  .time {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #858585;
  }
  .submission-btn,
  .next-btn {
    background: linear-gradient(
      335.64deg,
      #ff589b -19.22%,
      #ff136f 118.87%
    ) !important;
    border-radius: 43px;
    font-weight: 500;
    color: #fff !important;
    font-size: 16px;
  }

  .submission-btn:hover {
    color: #fff !important;
  }

  .instructions-btn,
  .later-btn {
    border: 1px solid #6c6c6c !important;
    box-sizing: border-box;
    border-radius: 43px;
    font-weight: 500;
    font-size: 16px;
    color: #6c6c6c !important;
  }
  .form-component-body {
    min-height: 200px;
  }
  .custom-modal-button {
    border: 1px solid #ff136f;
    box-sizing: border-box;
    border-radius: 43px;
    width: 128px;
    max-width: 100%;
    height: 42px;
    color: #ff136f;
    box-shadow: unset !important;
  }

  .next-btn,
  .later-btn {
    width: 128px;
    height: 42px;
  }
  .permission-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
  }
  .permission-quote {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: #474747;
  }
  .custom-modal-button:active {
    border: 1px solid #ff136f !important;
    background-color: unset !important;
    color: #ff136f !important;
  }
  .custom-modal-button:hover {
    border: 1px solid #ff136f !important;
    background-color: unset !important;
  }

  .form-contain-body {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6c6c6c;
  }
}

.form-form-detail-contain {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.modal-form-detail-contain-body {
  padding-bottom: 10px;
  .form-title {
    color: #6c6c6c;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
  }
  .form-description {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #474747;
  }
  label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #252b42;
  }
  .form-control {
    background: #f9f9f9;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
  }
  button[type="submit"] {
    background: linear-gradient(335.64deg, #ff589b -19.22%, #ff136f 118.87%);
    border-radius: 43px;
    border: none;
    padding: 9px 30px;
  }
  input[type="radio"],
  input[type="checkbox"] {
    margin-right: 10px;
  }
}

.form-success-alert{
  .swal-button--confirm{
    background-color: #6808ff;
  }
}

.form-response-table {
  .MuiSelect-select.MuiSelect-select {
    padding: 14px !important;
  }
  .MuiInputLabel-animated {
    padding-left: 14px;
    padding-right: 14px;
    margin-top: -6px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #d6d6d6;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 16px;
  }
}
.formHead-0-3-2{
  display: none !important;
}
