.course-layout {
    min-height: calc(100vh - 122px) !important;
    background-color: $white;

    .course-name {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        color: #353536;
    }
    .course-content-progress {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #1e1e1e;
    }
    .progress-div {
        .progress-bar {
            background-color: $green !important;
            height: 10px !important;
        }
    }
    .btn:focus {
        box-shadow: unset;
    }
    .previous-button {
        border: 1px solid #6c6c6c;
        box-sizing: border-box;
        border-radius: 43px;
        width: 128px;
        height: 42px;
    }
    .next-button {
        background: linear-gradient(335.64deg, #ff589b -19.22%, #ff136f 118.87%);
        border-radius: 43px;
        width: 128px;
        height: 42px;
        color: $white;
        margin-left: 5px;
    }
    .card {
        border: 0 !important;
        .shadow {
            box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.05);
        }
        border-radius: 10px;
    }

    @media (max-width: 768px) {
        .course-name {
            font-size: 0.9em;
        }
        .course-content-progress {
            font-size: 14px;
        }
        .previous-button,
        .next-button {
            min-width: 40%;
            max-width: 100px;
        }
    }
    @media (min-width: 768px) and (max-width: 1440px) {
        .course-content-progress {
            font-size: 17px;
        }
        .course-name {
            font-size: 17px;
        }
    }
}
