.result-component {
    .quiz-result-card {
        background: #ffffff;
        box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
    .status-div {
        width: 170px;
        color: white;
        background: linear-gradient(
            22.7deg,
            #343c97 -26.16%,
            #3372bb 6.39%,
            #329eda 37.74%,
            #31baec 61.85%,
            #31c4f3 76.32%
        );

        border-radius: 11px;
        padding: 5px;
        height: auto;
        margin: 0 auto;
        .your-score {
            font-size: 16px;
            font-weight: 700;
        }
        .marks {
            font-size: 36px;
            font-weight: 700;
            margin: 0;
        }
    }
}
