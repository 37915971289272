.activity-log-component {
  font-family: Poppins;
  font-style: normal;
  .title {
    font-weight: 600;
    font-size: 21px;
    line-height: 47px;
    color: #111111;
  }
  .input-group {
    span {
      border-radius: 5px 0 0 5px;
    }
    span,
    input {
      background: #f3f3f3;
      box-shadow: none;
    }
  }
  .filter-btn {
    width: fit-content;
    font-size: 18px;
    line-height: 21px;
    // background: #f3f3f3;
  }
}
