.assignment-start-component {
    .custom-modal-button {
        border: unset;
        text-decoration: underline;
    }

    .custom-modal-button:hover {
        background-color: white !important;
        color: $blue;
    }

    .custom-modal-button:active {
        background-color: white !important;
        color: $blue !important;
    }
    .assignment-team-member {
        padding: 4px 10px 4px 4px;
        border: 1px solid #d5d5d5;
        box-sizing: border-box;
        border-radius: 36px;
        p {
            font-size: 12px;
        }
    }
    .assignment-marks {
        font-family: Poppins;
        font-size: 24px;
        color: #5f5f5f;
        span {
            color: #4289ff;
        }
    }
}
