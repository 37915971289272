.login-register-layout {
    background-color: $white;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Poppins", sans-serif;
    height: 100%;
    min-height: 87.4vh;

    .login-image {
        margin: 60px 60px 60px 0px;
        .login-image-login {
            padding: 30px;
        }
    }

    a {
        color: $mid-light-blue;
        font-size: 17px;
        font-weight: normal !important;
    }
    .text-danger {
        display: inline-block;
        margin: 8px;
    }
    .auth-form {
        .PhoneInput {
          border-radius: 5px;
          padding: 18px 22px;
          color: #768192;
          border: 1px solid #d8dbe0;
          .PhoneInputInput {
            border: 0;
            color: #768192;
            &:focus {
              outline: 0;
            }
          }
        }
        box-shadow: 0px 2px 20px rgba(79, 133, 237, 0.15);
        border-radius: 5px;
        @media (max-width: 425px) {
            border-radius: 17px;
        }

        h2 {
            color: $dark-blue;
            font-size: 24px;
            font-weight: 600;
        }
        p {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: $black;
        }
    }

    .form-check-label {
        font-weight: 500;
        font-size: 17px;
        color: #969696;
    }
    .form-check-input {
        zoom: 1.5;
        margin: 0 !important;
        position: absolute;
        left: 0;
        top: 2px;
    }

    .text-underline {
        text-decoration: underline;
    }
    .auth-form-forgot {
        background: url("../../assets/images/authentication/forgetPass/forgetDownKey.png") left 70px top 80px no-repeat,
            url("../../assets/images/authentication/forgetPass/forgetUpperKey.png") right bottom 100px no-repeat;
        padding: 110px;
        background-size: 80px, 120px, auto;
    }

    .auth-btn {
        background: linear-gradient(-45deg, #ff589b, #ff136f);
        color: #fff;
        border: none;
        font-weight: bold;
        border-radius: 25px;
        line-height: 37px;
    }

    .auth-btn:active {
        background: linear-gradient(-45deg, #ff589b, #ff136f) !important;
        box-shadow: none;
    }

    input:focus {
        box-shadow: unset;
    }
    .login-btn {
        background: linear-gradient(86.89deg, #343c97 -111.91%, #31c4f3 79.41%);
        border-radius: 5px;
        width: 100%;
        padding: 15px 0px;
        color: $white;
        font-size: 20px;
        &:hover {
            background: linear-gradient(86.89deg, #31c4f3 50%, #343c97 170%);
        }
    }
    .login-to-sign-up-btn {
        color: $mid-light-blue;
        cursor: pointer;
    }
    .auth-btn .arrow-right {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
    }

    .form-group label {
        color: #2f2f41;
        font-weight: 500;
    }
    .form-control {
        border: 1px solid #c2c2c2;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 28px 22px;
    }
    .optional-fields {
        height: 60px;
        padding-left: 15px !important;
        appearance: none;
    }
    .selection {
        position: relative;
        .option-icon {
            position: absolute;
            right: 15px;
            top: 40%;
        }
    }

    .password-box {
        position: relative;
        .show-pass {
            padding-top: 15px;
            position: absolute;
            right: 5px;
            top: 0;
            font-size: 1.3em;
            color: #a7a7a7;
        }
        .btn:focus {
            box-shadow: none !important;
        }
    }
    @media (min-width: 576px) and (max-width: 644px) {
        .terms-text {
            font-size: 12px;
        }
    }
    @media only screen and (max-width: 375px) {
        .form-check-label,
        .terms-text {
            font-size: 12px;
        }
        .form-check-input {
            zoom: 1;
            margin: 0 !important;
            position: absolute;
            left: 0;
            top: 8px;
        }
        .resposive-text {
            font-size: 14px !important;
        }

        .responsive-title-text {
            font-size: 13px !important;
        }
        .remmber-text {
            font-size: 13px !important;
        }
    }

    @media only screen and (max-width: 575px) and (min-width: 320px) {
        .auth-form-forgot {
            margin-top: 20px;
            background: unset;
            padding: 0px;
            background-size: none;
        }
    }
    @media only screen and (max-width: 768px) and (min-width: 575px) {
        .auth-form-forgot {
            background: url("../../assets/images/authentication/forgetPass/forgetDownKey.png") left 35px top 40px
                    no-repeat,
                url("../../assets/images/authentication/forgetPass/forgetUpperKey.png") right 15px bottom 40px no-repeat;
            padding: 80px;
            background-size: 80px, 110px, auto;
        }
    }
}
