.create-batch-wrapper {
    .cancel-btn {
        color: $primary3;
        font-size: 16px;
        text-decoration: none;
    }
    .MuiGrid-item {
        padding-bottom: 0 !important;
    }
    .custom-file-container {
        position: relative;
        input {
            &:focus ~ .custom-file-label {
                box-shadow: none;
                border-color: $gray6;
            }
        }
        .custom-file-label {
            top: 45px;
            height: 46px;
            padding: 10px 15px;
            border-radius: 8px;
            color: $gray5;
            font-size: 16px;
            &::after {
                content: "";
                display: none;
            }
        }
    }
}
