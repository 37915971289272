.candidate-email-list-component {

	.cadidate-email-list-data {
		margin-top:50px;

		.email-to {
			font-family: Poppins;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			color: #323232;
		}

		.cc {
			font-family: Poppins;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 18px;
			color: #6C6C6C;
		}

		.profile-image {
			width:48px;
			height:47.98px;
		}


	}
}