.frequently-asked-list-component {
    .milestone-title {
        color: $dark02;
        font-size: 25px !important;
        font-weight: 700;
        margin-left: 20px;
    }

    .MuiAccordion-root {
        background-color: $light;
    }
    .faq-answer {
        color: $dark02;
        padding: 0px 40px;
        font-family: "ruposhi-bangla";
        font-size: 22px;
    }

    .MuiIconButton-label {
        font-size: 14px;
    }
    .MuiAccordionSummary-root {
        background-color: $white !important;
    }
    .MuiIconButton-edgeEnd {
        margin-top: 0px;
    }
    .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
        background-color: $white !important;
        border: 1px solid $dark06;
        &.Mui-expanded {
            box-shadow: 20px 20px 45px 2px rgba(0, 0, 0, 0.06);
            border-color: transparent;
        }
    }
    @media (max-width: 768px) {
        .milestone-title {
            font-size: 16px !important;
        }
        .MuiAccordionDetails-root p {
            font-size: 16px !important;
        }
        .faq-answer {
            font-size: 20px;
        }
    }
}
