.module-component {
    .MuiPaper-root {
        box-shadow: unset !important;
    }

    .custom-accordion-component {
        box-shadow: unset !important;
    }

    .MuiIconButton-edgeEnd {
        background: unset;
        color: $black;
        font-size: 10px !important;
        bottom: 0;
    }

    .MuiButtonBase-root {
        font-size: 18px;
        // padding: 0;
    }

    .MuiAccordionDetails-root {
        display: unset;
        margin: 0 !important;
        padding: 0 !important;
    }

    .module-group {
        display: unset !important;
    }

    .MuiAccordionSummary-root.Mui-expanded {
        min-height: unset;
        height: auto !important;
        margin-bottom: 25px;
        padding: 0 13px;
    }
    .MuiAccordionSummary-content {
        margin-bottom: 10px;
    }
    .MuiAccordionSummary-content.Mui-expanded {
        margin: 10px 0;
        padding: 0 0 10px 0;
    }
    .meta {
        height: 22px;
    }
    .MuiCollapse-wrapperInner {
        margin-top: -40px;
    }
}

.course-content-list {
    border-radius: 10px;
}
.module-group {
    .MuiAccordionDetails-root {
        display: unset;
    }
}

.MuiAccordionDetails-root {
    display: unset;
    margin: 0 !important;
    padding: 0 !important;
}
