.c-app.ph-admin-template .assignment-feedback-page-container {
  table {
    tr th {
      font-size: 15px;
      &:last-child {
        display: flex;
        flex-direction: column;
        align-items: end;
      }
    }
    tr td {
      &:last-child {
        display: flex;
        flex-direction: column;
        align-items: end;
      }
    }
  }
}
