.leader-board-body {
  font-family: "Inter";
  position: relative;
  &::before {
    content: "";
    background-image: url("../../assets/images/patterns.png");
    background-color: #343c97;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 428px;
    background-size: cover;
    z-index: -1;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 428px;
    background-position: top center;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/circle-bg.png");
    z-index: -1;
  }
  @media (max-width: 767px) {
    &::before {
      height: 405px;
    }
    &::after {
      height: 405px;
    }
  }
  .leader-board {
    .position-title {
      color: #fff;
      font-size: 25px;
      &.dark {
        color: #444444;
      }
      @media (max-width: 767px) {
        font-size: 20px;
        max-width: 375px;
        margin-inline: auto;
      }
    }
    .leader-board-select {
      appearance: none;
      background-image: url("./../../assets/icons/arrow-down.svg");
      background-repeat: no-repeat;
      background-position: right 1.8rem top 50%;
      height: 3rem;
      padding-inline: 1.8rem;
      background-size: 0.65rem auto;
      max-width: 375px;
      margin-inline: auto;
    }
    h4 {
      font-size: 20px;
      color: white;
      font-weight: 600 !important;
    }
    .your-position {
      background: white;
      border-radius: 5px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      color: #000000;
      .table-header h6 {
        color: #444444;
        font-weight: 600;
      }
    }
    .position-mobile {
      background-color: #fff;
      box-shadow: 0px 7px 50px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      max-width: 375px;
      margin: 16px auto;
    }

    .level2-top-positions {
      background: white;
      border-radius: 10px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      .table-header-mobile {
        p {
          font-size: 12px;
          font-weight: 500;
          color: #444;
        }
        .assignment {
          margin-right: -30px;
        }
      }
      .items-border {
        height: 1px;
        width: 98%;
        border-bottom: 1px solid #e8e8e8;
        margin-left: 1%;
        @media (max-width: 767px) {
          border-bottom: 0;
          background: radial-gradient(50% 50% at 50% 50%, rgba(114, 114, 114, 0.83) 0%, rgba(217, 217, 217, 0) 100%);
        }
      }
    }

    .rank {
      color: #5f5f5f;
      border-radius: 10px;
      .rank-no {
        font-weight: 600;
        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
      .profile-image-circle {
        border-radius: 50%;
        position: relative;
        height: 60px;
        width: 60px;
        margin: 5px 30px 5px 20px;
        @media (max-width: 767px) {
          margin: 5px 16px 5px 16px;
        }
        @media (max-width: 380px) {
          margin: 5px 12px 5px 12px;
        }
        @media (max-width: 370px) {
          margin: 5px 10px 5px 7px;
        }
        .profile-image {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
        .profile-crown {
          position: absolute;
          right: -10px;
          bottom: -1px;
        }
        @media (max-width: 767px) {
          height: 40px;
          width: 40px;
          .profile-image {
            width: 32px;
            height: 32px;
          }
          .profile-crown {
            right: -5px;
            height: 18px;
            width: 18px;
          }
        }
      }
      .name {
        font-weight: 600;
        font-size: 14px;
        color: #737373;
        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
      .dark-text-mobile {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #444444;
        @media (max-width: 380px) {
          font-size: 14px;
          line-height: 17px;
        }
        @media (max-width: 360px) {
          font-size: 12px;
          line-height: 16px;
        }
      }
      .gray-text-mobile {
        color: #737373;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        @media (max-width: 390px) {
          font-size: 11px;
          line-height: 18px;
          &.mr-3 {
            margin-right: 0.5rem !important;
          }
        }
        @media (max-width: 360px) {
          font-size: 10px;
          line-height: 16px;
        }
      }
      .mark-info {
        .assignment {
          color: #444444;
          font-weight: 600;
          font-size: 16px;
        }
        .assignment-mark {
          color: #343c97;
          font-size: 16px;
          font-weight: 600;
        }
        svg {
          color: #cec7c7;
          margin: 0;
          margin-left: 14px;
        }
      }
    }
  }
  .radio-label {
    font-size: 20px;
  }
  @media (max-width: 767px) {
    .radio-label {
      font-size: 16px;
    }
    .level2-top-positions {
      max-width: 375px;
      margin-inline: auto;
    }
    .mark-info {
      .assignment {
        font-size: 14px !important;
      }
      .assignment-mark {
        color: #444444 !important;
        font-size: 14px !important;
      }
    }
  }
  @media (max-width: 460px) {
    .radio-label {
      font-size: 14px;
    }
    .rank {
      .dark-text-mobile {
        font-size: 14px !important;
        line-height: 16px;
      }
    }
    .mark-info {
      .assignment,
      .assignment-mark {
        font-size: 12px !important;
      }
    }
  }
  @media (max-width: 380px) {
    .dark-text-mobile {
      font-size: 14px;
      line-height: 16px;
    }
    .gray-text-mobile {
      font-size: 10px;
      line-height: 22px;
    }
  }
}
