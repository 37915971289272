.course-summery {
    background-color: $alabaster;
    .course-info-card {
        border: 1px solid $gray6;
        font-family: Poppins;
        overflow: hidden;
        .course-title {
            font-size: 20px;
            font-weight: 600;
            color: $outer-space;
        }
        .author-name {
            color: $primary3;
        }
    }
    .tundora-text {
        color: $tundora;
    }
    .dove-gray-text {
        color: $dove-gray;
    }
    .fs-500 {
        font-weight: 500;
    }
    .marks-tab {
        font-family: Poppins;
        .title {
            color: $gray-dark;
            font-size: 20px;
            font-weight: 500;
        }
    }
    .highlight {
        color: $primary3;
        position: relative;

        // display: none;
        // &::before {
        //     position: absolute;
        //     content: "";
        //     border-bottom: 6px solid #fff;
        //     border-left: 8px solid transparent;
        //     border-right: 8px solid transparent;
        //     top: -6px;
        //     left: 50%;
        //     transform: translateX(-50%);
        // }

        // &:hover .mini-modal {
        //     display: block;
        // }
    }

    .course-result {
        h3 {
            font-size: 18px;
            color: $gray-dark;
        }
        .total-mark {
            background-color: rgba(53, 156, 188, 0.1);
        }
        .total-gradepoint {
            background-color: rgba(0, 201, 167, 0.2);
        }
        .grade {
            background-color: rgba(0, 136, 186, 0.2);
        }
        .marks-item {
            width: 50px;
            text-align: center;
        }
    }
    .MuiRating-label {
        top: 2px;
    }
    .thumb {
        border-radius: 5px;
    }

    button:disabled {
        color: #6c6c6c !important;
        background-color: #e5e5e5 !important;
    }
    .border-radius {
        border-radius: 12px;
    }
    .semester-overview-button {
        background-color: $primary3 !important;
        color: #fff !important;
        text-transform: capitalize;
        padding: 10px 30px;
        font-size: 16px;
        border-radius: 10px;
        font-family: Poppins;
    }
    .student-feedback {
        .profile-image {
            border-radius: 50%;
            height: 72px;
            width: 72px;
            object-fit: cover;
        }
        .student-name {
            font-size: 24px;
            color: $black;
        }
        p {
            color: #8d8d8d;
            font-weight: 500;
        }
    }
    .feedback {
        .title {
            font-weight: 500;
            font-size: 20px;
            color: $mine-shaft;
        }
        textarea {
            margin-top: 10px;
            background: #eaeaea;
            box-sizing: border-box;
            border-radius: 4px;
            box-shadow: none;
        }
        .feedback-button,
        .cancel-button {
            color: #fff !important;
            text-transform: capitalize;
            padding: 7px 40px;
            font-size: 18px;
            &:disabled {
                color: #bdbdbd !important;
                background-color: #eaeaea !important;
            }
        }
        .feedback-button {
            background-color: $primary3 !important;
        }
        .cancel-button {
            background-color: $secondary !important;
        }
        .label,
        p {
            margin-bottom: 1.5em;
            display: block;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #263238;
        }
    }
    .course-summery-download-button {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }
}
.mini-modal-overly {
    font-size: 8px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px;
    color: #7e7e7e;
}
