.generic-enrolled-courses {
    .welcome-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        span {
            color: $dark-blue;
            font-weight: 800;
        }
    }
    .custom-pagination {
        button {
            border-color: #f5f5f5 !important;
            border-radius: 8px !important;
            font-family: Poppins;
            font-size: 14px;
            color: #858585;
            font-weight: 400;
            height: 40px;
            width: 40px;
        }
        .MuiPaginationItem-page.Mui-selected {
            background: $primary3;
            color: white;
            border-color: $primary3;
        }
        .MuiPaginationItem-page:focus {
            outline: $primary3;
        }
    }
    @media screen and (max-width: 425px) and (min-width: 375px) {
        .welcome-title {
            font-size: 19px;
        }
    }
    @media only screen and (max-width: 320px) and (min-width: 0px) {
        .welcome-title {
            font-size: 15px;
        }
    }
    .courses-tab {
        .MuiTab-wrapper {
            font-size: 18px;
            font-family: Montserrat;
        }
        .MuiTab-root {
            color: $outer-space;
            opacity: 0.6;
            font-weight: 700;
            &.Mui-selected {
                color: $outer-space;
                opacity: 1;
            }
        }
        .MuiTabs-indicator {
            height: 5px;
            border-radius: 5px;
            background-color: $primary;
        }
        .MuiTabs-scrollable {
            height: 43px;
        }
    }
    .generic-course-single {
        .course-thumb {
            img {
                height: 160px !important;
                object-fit: contain !important;
            }
        }
        .course-info {
            min-height: 160px;
            .batch-semester-name {
                color: $raven;
                font-size: 14px;
                font-family: Poppins;
                font-weight: 400;
            }
            .course-name {
                font-family: Montserrat;
                font-weight: 700;
                font-size: 19px;
                color: $dark-blue;
            }
            .course-content {
                p {
                    font-size: 12px;
                    font-family: Poppins;
                    color: $raven;
                    font-weight: 400;
                    span {
                        color: $black;
                        font-weight: 600;
                    }
                }
            }
            .course-progress {
                span {
                    font-size: 16px;
                    font-family: Montserrat;
                    color: $raven;
                    font-weight: 400;
                }
                .progress {
                    width: 65%;
                    height: 12px;
                    border-radius: 22px;
                    margin: 0 10px;
                    .progress-bar {
                        background-color: $green;
                    }
                }
            }
        }

        .no-content-msg {
            min-height: 160px;
            h4 {
                font-family: "Montserrat";
                font-weight: 700;
                font-size: 16px;
                color: $raven;
            }
        }
        .column-label {
            color: $light-black;
            font-family: "Montserrat";
            font-weight: 700;
            font-size: 16px;
        }
        .title {
            font-family: "Montserrat";
            color: $dark-blue;
            font-weight: 600;
            font-size: 18px;
            a {
                font-weight: 800;
            }
        }
        .continue-course-button {
            background: $button-gradient;
            border-radius: 52px;
            font-family: "Poppins";
            text-transform: capitalize;
            color: $white;
            font-weight: 600;
            font-size: 16px;
            max-width: 260px;
            &:hover {
                opacity: 0.8;
            }
        }
        .next-module-info {
            min-height: 160px;
            .duration {
                font-weight: 400;
                font-size: 15px;
                color: $silver-chalice;
                .dot {
                    display: inline-block;
                    background-color: $silver-chalice;
                    height: 6px;
                    width: 6px;
                    border-radius: 50%;
                }
            }
        }
    }
}
