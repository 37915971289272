.assignment-recheck-notice {
    text-align: center;
    text-align: center;
    .assignment-message {
        font-family: Hind Siliguri;
        font-style: normal;
        font-weight: normal;
        color: #1c1c1c;
        font-size: 24px;
        line-height: 39px;
    }
    @media (max-width: 768px) {
        width: unset;
        padding-left: unset;
        .assignment-message {
            font-size: 15px;
            line-height: 28px;
        }
    }
    .reason-textarea {
        resize: none !important;
        border-radius: 14px;
        padding: 10px;
    }
    .reason-textarea:focus {
        border-radius: 14px;
        outline: unset;
    }
}
