.about-us-page {
    background-color: #010313;
    .page-header {
        margin-top: -2px;
        min-height: unset !important;
        background: #080240;
        h2 {
            font-family: Poppins;
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            margin-top: 100px;
        }
        p {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-align: justify;
        }
    }
    .team-member {
        .name {
            margin-top: 0;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            color: #0f0f0f;
        }
        .designation {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: #0f0f0f;
        }
    }
}
