.module-video-component {
  iframe,
  #horizon {
    width: 720px !important;
    height: 460px !important;

    @media (max-width: 700px) {
      width: 100% !important;
      height: 240px !important;
    }
    @media (max-width: 375px) {
      height: 200px !important;
    }
  }
  #secondray-player {
    width: 100% !important;
    margin-bottom: -6px !important;
  }
  position: relative;
  .play {
    position: absolute;
    top: 45%;
    left: 45%;
    display: none;
  }
  video:focus {
    border: 0;
    box-shadow: none;
    outline: 0;
  }
  div {
    width: 100% !important;
  }

  @media screen and (min-width: 768px) and (max-width: 990px) {
    div {
      margin-top: -67px;
    }
  }

  @media screen and (min-width: 991px) {
    div {
      margin-top: -27px;
    }
  }

  @media screen and (min-width: 1198px) {
    div {
      margin-top: 20px;
    }
  }
}

.module-video-component > div {
  width: 100% !important;
  height: auto !important;
}
.ytp-volume-panel {
  width: 100px !important;
  background-color: #d3d3d3 !important;
  accent-color: #ffffff;
  zoom: 0.65;
}

.ytp-forward-button {
  margin-left: -15px !important;
}
