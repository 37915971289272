.blog-page-component {
  h5 {
    font-family: "Poppins", "Hind Siliguri";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #303030;
  }

  .blogs-content {
    h3 {
      font-family: "Poppins", "Hind Siliguri";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #303030;
    }

    @media (max-width: 768px) {
      .w-md-100 {
        width: 100% !important;
      }
    }
  }

  .filter-section {
    max-width: 992px;
    min-width: 500px;

    @media screen and (max-width: 531px) {
      min-width: 300px;
    }

    .filter-input {
      position: relative;
      border: 0;
      background: #f2f2f2;
      box-shadow: none;
      border-radius: 10px;
      min-height: 50px;
      // color: #303030;
    }

    .form-control {
      padding-left: 40px;
    }

    .filter-icon {
      position: absolute;
      top: 13px;
      left: 15px;
      padding-left: 15px;
      svg {
        path {
          stroke: #303030;
        }
      }
    }

    .category-post {
      display: inline-block;
      width: 20px;
      height: 18px;
      background-color: #d2d2d2;
      color: #f2f2f2;
      border-radius: 50%;
    }

    .active-category-post {
      color: #fff;
      background-color: rgba(96, 71, 236, 0.7);
    }
  }

  .card {
    background: #fff;
    box-shadow: 0px 0px 10px rgba(18, 17, 39, 0.1);
    border-radius: 20px;
    overflow: hidden;
    border: none;
    width: 100%;
    max-width: 400px;
    height: 100%;
    min-height: 580px;
    position: relative;
    transition: 0.3s ease;
    margin: 0 auto;
    .blog-thumbnail {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        transition: 0.3s ease-in-out;
        opacity: 0;
        background: linear-gradient(
          100deg,
          rgba(0, 0, 255, 0.3),
          rgba(0, 0, 255, 0)
        );
        z-index: 2;
      }
    }
    &:hover {
      transform: translateY(-4px);
      box-shadow: 0px 0px 20px rgba(18, 17, 39, 0.15);
      .blog-thumbnail {
        &::before {
          opacity: 1;
        }
        img {
          transform: scale(1.05);
        }
      }
    }

    .category-btn {
      background-color: rgba(96, 71, 236, 0.6);
      box-shadow: none;
      backdrop-filter: blur(10px);
      border-radius: 20px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      text-transform: capitalize;
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      min-width: 130px;
    }
    .blog-thumbnail {
      overflow: hidden;
      img {
        width: 100%;
        object-fit: cover;
        height: 300px;
        min-height: 300px;
        transition: 0.3s ease;
      }
    }

    .card-body {
      .date-minute {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #303030;
        background: transparent;
      }

      .content-section {
        font-family: "Poppins", "Hind Siliguri";
        .card-title {
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 28px;
          color: #303030;
          display: -webkit-box;
          display: -moz-box;
          -webkit-line-clamp: 2;
          -moz-line-clamp: 2;
          -webkit-box-orient: vertical;
          -moz-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .card-text {
          font-style: normal;
          font-weight: 300;
          font-size: 17px;
          line-height: 180%;
          color: #303030;
          display: -webkit-box;
          display: -moz-box;
          -webkit-line-clamp: 3;
          -moz-line-clamp: 3;
          -webkit-box-orient: vertical;
          -moz-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .author-name {
          font-style: normal;
          font-weight: 400;
          font-size: 15.5876px;
          line-height: 180%;
          color: #303030;
        }

        .release-date {
          font-style: normal;
          font-weight: 500;
          font-size: 17.8521px;
          line-height: 180%;
          color: #959595;
        }

        p {
          h1 {
            color: #1a1a1a;
            font-size: 16px;
            font-weight: 400;
            margin: 0px;
          }

          h2 {
            color: #1a1a1a;
            font-size: 16px;
            font-weight: 400;
            margin: 0px;
          }

          h3 {
            color: #1a1a1a;
            font-size: 16px;
            font-weight: 400;
            margin: 0px;
          }

          h4 {
            color: #1a1a1a;
            font-size: 16px;
            font-weight: 400;
            margin: 0px;
          }

          h5 {
            color: #1a1a1a;
            font-size: 16px;
            font-weight: 400;
            margin: 0px;
          }

          h6 {
            color: #1a1a1a;
            font-size: 16px;
            font-weight: 400;
            margin: 0px;
          }

          strong {
            color: #1a1a1a;
            font-size: 16px;
            font-weight: 400;
            margin: 0px;
          }

          span {
            color: #1a1a1a;
            font-size: 16px;
            font-weight: 400;
            margin: 0px;
          }

          p {
            color: #1a1a1a;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 4px;
          }
        }
      }
    }
  }

  .tag-btn {
    background-color: #f2f2f2;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #303030;
    border: 0;
    outline: none;
    border-radius: 20px;
    min-width: 90px;
    &:hover {
      cursor: pointer;
    }
  }

  .active-tag {
    background-color: rgba(96, 71, 236, 0.8);
    box-shadow: none;
    border-radius: 20px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #fff;
  }

  .recommend-section {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 200px 200px;
    gap: 20px;
    .feature1 {
      grid-column: 1/8;
      grid-row: 1/3;
      .feature-blog {
        padding-bottom: 80px;
        .blog-thumbnail {
          img {
            max-height: 350px;
          }
        }
        .show-up-content {
          transform: translateY(100%) translateY(-120px);

          .category-title {
            font-size: 26px;
          }
          .body-text {
            font-size: 18px;
          }
        }
      }
    }
    .feature2,
    .feature3 {
      grid-column: 8/13;
      .feature-blog {
        padding-bottom: 60px;
        .blog-thumbnail {
          img {
            max-height: 150px;
          }
        }
        .show-up-content {
          transform: translateY(100%) translateY(-100px);
          .category-title {
            font-size: 20px;
          }
          .body-text {
            font-size: 14px;
          }
        }
      }
    }
    .feature-blog-container {
      overflow: hidden;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: 0.3s ease-in-out;
      .feature-blog {
        position: relative;
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        transition: 0.3s ease-in-out;
        background-color: #fff;
        .blog-title {
          transition: 0.3s ease-in-out;
        }

        .category-btn {
          position: absolute;
          top: 0;
          left: 0;
        }
        .blog-thumbnail {
          img {
            width: 100%;
            min-height: 150px;
            object-fit: cover;
            border-radius: 10px 10px 0 0;
            transition: 0.3s ease-in-out;
          }
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          border-radius: 10px;
          transition: 0.3s ease-in-out;
          background: linear-gradient(
            0deg,
            rgba(96, 71, 236, 0.6),
            rgba(67, 67, 164, 0.2)
          );
        }
        .show-up-content {
          position: absolute;
          left: 15px;
          bottom: 0;
          transition: transform 0.3s linear;
          .category-title {
            color: #303030;
            display: -webkit-box;
            display: -moz-box;
            -webkit-line-clamp: 1;
            -moz-line-clamp: 1;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            line-height: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .body-text {
            color: #fff;
            display: none;
          }
        }
      }
      &:hover {
        box-shadow: none;
        .feature-blog {
          padding-bottom: 0px;
          margin-bottom: 120px;
          .overlay {
            opacity: 1;
          }
          .blog-thumbnail {
            img {
              border-radius: 10px;
            }
          }
          .show-up-content {
            transform: translateY(0%);
            .category-title {
              color: #fff;
            }
            .body-text {
              display: block;
            }
          }
        }
      }
    }

    .display-content {
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 10px;
      cursor: pointer;
      overflow: hidden;
      position: relative;
    }

    .category-btn {
      background-color: rgba(96, 71, 236, 0.6);
      box-shadow: none;
      backdrop-filter: blur(10px);
      border-radius: 20px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      text-transform: capitalize;
      color: #fff;
      z-index: 1;
      min-width: 130px;
    }
  }
  @media (max-width: 992px) {
    .recommend-section {
      grid-template-columns: 1fr;
      grid-template-rows: unset;
      .feature1,
      .feature2,
      .feature3 {
        grid-column: unset;
        grid-row: unset;
        .feature-blog {
          padding-bottom: 90px;
          .blog-thumbnail {
            img {
              max-height: 450px;
            }
          }
          .show-up-content {
            transform: translateY(100%) translateY(-120px);

            .category-title {
              font-size: 30px;
              line-height: 3;
            }
            .body-text {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .recommend-section {
      .feature1,
      .feature2,
      .feature3 {
        .feature-blog {
          padding-bottom: 80px;
          .show-up-content {
            transform: translateY(100%) translateY(-100px);
            .category-title {
              font-size: 22px;
            }
            .body-text {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  .Mui-selected {
    color: #fff;
  }

  .swiper-container {
    padding: 0px 0px 50px 0px !important;
  }
  .blog-page-pagination {
    .custom-pagination {
      li {
        button {
          background: #fff;
          color: #000;
          box-shadow: none;
          &.Mui-selected {
            color: #fff;
            background: #6047ec;
            border-color: #6047ec;
          }
          &:focus {
            outline: 0;
          }
        }
      }
    }
  }
}
