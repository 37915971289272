.course-content-component {
    // padding: 10px;

    .milestone-content {
        background: #ffffff;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        // border-left:  1px  solid #1e1747;
        // margin-top: 10px;
    }
    .MuiIconButton-edgeEnd {
        height: 28px;
        width: 28px;
        color: #fff;
        background: linear-gradient(
            22.7deg,
            #343c97 -26.16%,
            #3372bb 6.39%,
            #329eda 37.74%,
            #31baec 61.85%,
            #31c4f3 76.32%
        );
        font-size: 25px;
        margin-right: 0px;
        border-radius: 3px;
        svg {
            font-size: 18px;
        }
    }

    .unit-component {
        .cursor-pointer {
            cursor: pointer;
        }
        a:hover {
            text-decoration: none;
        }
    }
    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #c4c4c4;
        border-radius: 24px;
        min-height: 50px;
    }

    .course-content-list {
        overflow-y: auto !important;
        height: unset !important;
        max-height: 500px;
        // padding: 0 10px;
    }

    .complete-course-button {
        width: 100%;
        height: 55px;
        margin-top: 20px;
        background: #d3d3d3;
        border-radius: 5px;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        outline: unset !important;
        border: none;
        cursor: not-allowed;
    }

    .complete-course-button-success {
        padding: 15px;
        background: #4289ff;
        height: 55px;
        width: 100%;
        margin-top: 20px;
        color: white;
        border-radius: 5px;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        outline: unset !important;
        border: none;
    }
}
