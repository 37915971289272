.create-course-component {
    padding: 30px;
    .courseThumnail-preview {
        max-height: 80px;
        width: auto;
        max-width: 100%;
    }
}
@media (max-width: 600px) {
    .create-course-component {
        padding: 0;
    }
}
.view-course-component {
    p,
    h4 {
        font-size: 14px;
        font-family: Poppins;
    }
    p {
        color: $gray4;
        font-weight: 400;
        margin: 5px 0;
    }
    h4 {
        color: $black;
        font-weight: 500;
    }
    .editor-name {
        color: $primary3;
        font-weight: 600;
    }
}
