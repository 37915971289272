.student-single-project {
    .project-details {
        h3 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: #121619;
        }
        p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400 !important;
            font-size: 16px !important;
            color: #353535 !important;
        }
        a {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500 !important;
            font-size: 14px !important;
            color: #407bff !important;
        }
    }
}
.student-single-project-backround {
    background: rgba(205, 216, 255, 0.18);
}
