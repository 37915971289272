.create-batch-wrapper {
    .un-acive {
        padding: 10px 25px;
        width: 10%;
    }
    .active {
        background: $primary;
        padding: 10px 25px;
        border-radius: 50px;
        width: 10%;
        color: $white;
    }
    .submit-button {
        outline: 0 !important;
        box-shadow: none !important;
    }
}
.tag-list-table-component {
    .tag {
        display: inline-block;
        color: #fff;
        background-color: #006dff87;
        border-radius: 22px;
        min-width: 115px;
        padding: 3px 5px;
    }
}
