$blue: #5a00dd;
$light-sky-blue: #56a3ff;
$light-blue: #684efb;
$pink: #ff193f;
$light-pink: #ff35b4;
$yellow: #ff9c35;
$light-yellow: #ffd019;
$light-tomato: #f59e75;
$white: #ffffff;
$dark-grey: #626262;
$payment-chek-item: #0dc574;
$mid-light-blue: #21a9ff;
$mid-black: #101010;
$light-black: #353536;
$dark-blue: #343c97;
$light-primary: #d22df0;
$ghost-white: #f5f7ff;
$skyBlue: #2eb1ea;
$light-grey: #efefef;
$mid-light-grey: #e5e5e5;
$green: #3ac55a;
$success: #02b254;
$dodger-blue: #007bff;
$red: #f92600;
$secondary: #ff136f;
$white: #ffffff;
$black: #000000;
$light-black: #121619;
$light-dark: #300063;
$light: #ffffff;
$gray: #575757;
$gray-dark: #555555;
$gray2: #6b6b8d;
$gray3: #c4c4c4;
$gray4: #858585;
$gray5: #adadad;
$gray6: #d6d6d6;
$gray-button: #6c6c6c;
$primary: #343c97;
$primary2: #4289ff;
$primary3: #407bff;
$chocolate: #3e3e3e;
$student-info: #4f85ed;
$enroll-button: #ff428d;
$blue-overlay: #eaf4fe;
$blue-gradient: linear-gradient(
    48.8deg,
    #343c97 -35.21%,
    #3372bb 3.24%,
    #329eda 40.37%,
    #31baec 68.21%,
    #31c4f3 85.45%
);
$alabaster: #fcfcfc;
$outer-space: #263238;
$tundora: #4a4a4a;
$dove-gray: #666666;
$magnolia: #faf7ff;
$mine-shaft: #3b3b3b;
$raven: #6e798c;
$silver-chalice: #a2a2a2;
$button-gradient: linear-gradient(
    344.66deg,
    #343c97 -80.56%,
    #3372bb -4.55%,
    #329eda 68.85%,
    #31baec 123.9%,
    #31c4f3 157.97%
);

// new landing page
$dark01: #141414;
$dark02: #282828;
$dark03: #434343;
$dark04: #727272;
$dark05: #a1a1a1;
$dark06: #e8e8e8;
$dark07: #f3f3f3;
$dark08: #1c1c1c;
$blue01: #583cea;
$violet03: #583cea;
$lightBlue: #f5f7ff;
$light02: #fafafa;
$gray02: #707070;
$tomato: #fd8005;
$violet01: #8951a3;
$light-red: #e34856;
$blue02: #583cea;
:export {
    primary: $primary;
    primary2: $primary2;
    blueGradient: $blue-gradient;
    grayButton: $gray-button;
    studentInfo: $student-info;
    enrollButton: $enroll-button;
    primary3: $primary3;
    gray4: $gray4;
    gray5: $gray5;
    gray6: $gray6;
    grayDark: $gray-dark;
    magnolia: $magnolia;
    blue01: $blue01;
    lightBlue: $lightBlue;
    white: $white;
    dark04: $dark04;
    dark02: $dark02;
}
