.order-history{
    
    .round-n-shadow{
        box-shadow: 0px 5.64912px 14.1228px rgba(0, 0, 0, 0.1) !important;
        border-radius: 0 !important;
    }
    p{
        font-weight: 600;
        font-size: 15.8447px;
        line-height: 24px;
        color: #000000;
    }
    .course{
        background: #F3F5F9;
        border-radius: 10px 10px 0 0;
        h2{
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 23px;
            line-height: 36px;
            color: #090E1B;
        }
    }
    .course,
    .data{
        padding-left: 98px !important;
        padding-right: 98px !important;
    }
    .download-btn{
        a{
            color: #fff;
            text-decoration: none;
            &:hover{
                color: #fff;
                text-decoration: none;
            }
        }
    }
    @media(max-width:768px) {
        td,th{
            white-space: nowrap;
        }
    }
}