.course-outline-page {
  h2 {
    background: #583cea;
    border-radius: 12px;
    text-align: center;
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    color: #ffffff;
    margin-block: 32px;
    @media (max-width: 600px) {
      font-size: 20px;
      margin-top: 40px;
      margin-bottom: 16px;
    }
  }
}
