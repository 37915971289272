.enroll-notice-component {
    padding: 0 20px;
    .notice {
        margin-block: 100px;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 60px;
        .title {
            font-weight: 600;
            color: $black;
            margin-bottom: 45px;
            font-size: 40px;
        }
        .notice-item {
            font-family: "Hind Siliguri", "Fira Sans";
            margin-bottom: 25px;
            p {
                color: $gray02;
                font-size: 22px;
                margin: 0;
                font-weight: 400;
            }
            h3 {
                font-size: 32px;
                color: $dark02;
                font-weight: 600;
            }
            .icon {
                margin-right: 30px;
            }
        }
        .waiting-time {
            text-align: center;
            background-color: rgba($color: $blue01, $alpha: 0.05);
            border-radius: 8px;
            padding: 22px;
            h3 {
                font-weight: 700;
                font-size: 35px;
                color: $blue01;
            }
        }
        .price {
            font-weight: 700;
            font-size: 30px;
            color: $blue01;
        }
        .enroll-btn {
            max-width: 400px;
            margin: 30px auto 0 auto;
        }
    }
    @media (max-width: 1000px) {
        .notice {
            margin-top: 0px;
            .waiting-time {
                h3 {
                    font-size: 22px;
                }
            }
            .price {
                font-size: 22px;
            }
            .notice-item {
                p {
                    font-size: 18px;
                }
                h3 {
                    font-size: 22px;
                }
            }
        }
    }

    @media (max-width: 500px) {
        .notice {
            padding: 40px 20px;
            .title {
                font-size: 35px;
            }
            .notice-item {
                .icon {
                    margin-right: 15px;
                    img {
                        height: 40px;
                    }
                }
                p {
                    font-size: 16px;
                }
                h3 {
                    font-size: 20px;
                }
            }
            .price {
                h3 {
                    font-size: 24px;
                }
            }
        }
    }
}
