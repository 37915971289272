.course-content-skeleton{
    .skeleton-btn{
        border-radius: 43px;
    }
    .skeleton-video-player{
        border-radius: 5px;
    }
    .skeleton-milestone{
        background: #F1F1F1;
        border-radius: 10px;
    }
    .skeleton-btn.disabled{
        background: #F2F2F2;
    }
    .skeleton-course-name{
        height: 34px;
        width: 494px;
    }
    @media (max-width:1000px) {
        .skeleton-course-name,
        .skeleton-module-title,
        .skeleton-video-player
        {
            width: 100% !important;
            
        }
       
    }
    @media (max-width:700px) {
        .skeleton-video-player{
            height: 300px !important;
        }
    }
}