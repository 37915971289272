.enrolled-coursses-parent {
    h3 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        span {
            color: $light-primary;
        }
    }
    @media only screen and (max-width: 320px) and (min-width: 0px) {
        h3 {
            font-size: 15px;
            span {
                color: $light-primary;
            }
        }
    }
    @media screen and (max-width: 425px) and (min-width: 375px) {
        h3 {
            font-size: 19px;
            span {
                color: $light-primary;
            }
        }
    }
}
