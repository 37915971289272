.post-component {
	box-shadow: 0px 4px 18px 0px #0000001a;
	.post-component-body {
		min-height: 200px;
	}
	.custom-modal-button {
		border: 1px solid #4289ff;
		box-sizing: border-box;
		border-radius: 43px;
		width: 128px;
		max-width: 100%;
		height: 42px;
		color: #4289ff;
		box-shadow: unset !important;
	}

	.custom-modal-button:active {
		border: 1px solid #4289ff !important;
		background-color: unset !important;
		color: #4289ff !important;
	}
	.custom-modal-button:hover {
		border: 1px solid #4289ff !important;
		background-color: unset !important;
		color: #4289ff !important;
	}

	.post-contain-body {
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		color: #6c6c6c;
	}
}

.modal-post-detail-contain {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #6c6c6c;
	height: 405px;
	padding-left: 21px;
}

.modal-post-detail-contain-body {
	overflow-y: auto;
	height: 400px;
}

.post-close-icon {
	height: 42px;
	width: 42px !important;
	padding: 12px;
	position: absolute;
	right: -19px;
	border-radius: 31px;
	background-color: #ff3838;
	color: white;
	top: -15px;
	cursor: pointer;
}
