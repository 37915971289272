.profile-discussion-list-component {
	
	.discussionTrashIcon {
		width: 24px;
		height: 24px;
	}

	.discussion-proile-section {
		height: 36px;
	}

	.discussion-proile-image {
		width: 36px;
		height: 36px;
	}

	.discussion-profile-name {
		height: 24px;
	}
	
	.discussion-send-button {
		width: 75px;
		height: 28px;
		background: #5E24F9;
		border-radius: 4px;
		color: #fff;
		border: unset;
	}
	.cursor-poiner {
		cursor: pointer;
	}
}