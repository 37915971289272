.student-dashboard-skeleton-loader {
    .radius {
        border-radius: 20px;
    }
    .skeleton-btn {
        background: #c4c4c4;
        border-radius: 52px;
        max-width: 250px;
    }
    .skeleton-thumbnail {
        border-radius: 10px;
        height: 160px;
        width: 100%;
    }

    .enrolled-courses {
        width: 100%;
        background-color: #f1f1f1;
    }
}
