.create-assignment-component {
    .short-description-col {
        textarea {
            resize: none;
        }
    }
}

.email-form{
    .ql-editor{
        min-height:250px;
    }
    
}