.notifications {
    .announcements {
        background: #ffffff;
        box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
    a {
        word-break: break-word;
        color: #1f1f1f;
        text-decoration: none;
        span {
            color: #888888 !important;
        }
        position: relative;
    }
    a:hover {
        background: #ebebeb;
    }
    a::after {
        content: "\203A";
        color: #4c4a4a;
        font-size: 22px;
        padding-right: 0;
        position: absolute;
        right: 10px;
        top: 5px;
    }

    a.prevent-redirect::after {
        all: unset;
    }

    h5 {
        font-size: 16px;
        font-weight: 500;
    }
    .unseen {
        background: #ffffff;
        position: relative;
        h5 {
            font-weight: 600;
        }
    }

    .unseen::after {
        content: "";
        position: absolute;
        top: calc(50% - 3px);
        right: 30px;
        display: block;
        width: 7px;
        height: 7px;
        background: linear-gradient(
            48.8deg,
            #343c97 -35.21%,
            #3372bb 3.24%,
            #329eda 40.37%,
            #31baec 68.21%,
            #31c4f3 85.45%
        );
        border-radius: 50%;
        font-weight: bold;
    }

    .announcementDiv {
        background-color: #f0eaff;
    }
    .single-announcement {
        min-height: 137px;
        border-bottom: 1px solid #e3e3e3;
        cursor: pointer;

        p {
            font-size: 14px;
            word-break: break-all;
        }
        .sub-details {
            font-family: "Hind Siliguri";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #525252;
            * {
                margin: 0;
                padding: 0;
                display: inline;
            }
        }
        .time {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #888888;
        }
    }
    .announcement-details {
        background: #ffffff;
        box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        word-break: break-all;
        .view {
            height: 465px;
            overflow-y: auto;
        }

        h4 {
            font-family: "Hind Siliguri";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            color: #1f1f1f;
        }
        p {
            font-family: "Hind Siliguri";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #525252;
        }
    }
    .MuiPaginationItem-page.Mui-selected {
        background-color: $primary;
        border-color: $primary;
        color: #fff;
    }
    .announcement-details .view::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    .announcement-details .view::-webkit-scrollbar-track {
        box-shadow: none;
        background-color: transparent;
    }

    .announcement-details .view::-webkit-scrollbar-thumb {
        background-color: #ededed;
        outline: 0;
        border-radius: 10px;
    }

    .single-announcement.active {
        background: rgba(186, 104, 200, 0.03);
        border-radius: 4px 4px 0px 0px;
    }
    .single-announcement {
        h5 {
            font-family: "Hind Siliguri";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #1f1f1f;
        }
    }
    a.prevent-redirect {
        pointer-events: none;
    }
    .items::-webkit-scrollbar {
        width: 8px;
    }

    .items::-webkit-scrollbar-track {
        box-shadow: none;
    }

    .items::-webkit-scrollbar-thumb {
        background-color: #ededed;
        outline: 0;
        border-radius: 8px;
    }
}
