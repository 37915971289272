.dashboard-sidebar-logo {
        height: 25px;
}

.bg-gradient-info-light, .bg-gradient-success-light {
        // background: #2982cc !important;
        // background: linear-gradient(45deg, #38c2d1e0 0%, #2982cc4f 100%) !important;
        // border-color: #9aacba !important;

        background-color: #fff !important;
        border-color: transparent !important;
        color: #000 !important;
        .small{
                font-size: 12px !important;
                span{
                        margin-right: 7px;
                }
        }
}

// .bg-gradient-success-light {
//         // background: #1b9e3e !important;
        // background: linear-gradient(45deg, #03a9f459 0%, #d9ffdf 100%) !important;
//         // border-color: #cfdcd2 !important;
//         background-color: #fff !important;
//         border-color: transparent !important;
//         color: #000 !important;
//         .small{
//                 font-size: 13px !important;
//         }
// }

.selector {
        .rounded:focus{
                box-shadow: none !important;
                outline: 0 !important;
        }
}