.terms-conditions {
    h2 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: $black;
    }
    .terms-content {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $dark-grey;
    }
    @media only screen and (max-width: 425px){
        .terms-responsive-text{
            font-size:22px;
            text-align:left !important;
        }
        span{
            text-align: left !important;
        }
    }
}
