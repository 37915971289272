.add-new-profile-details {
    min-height: 65vh;
    .icon img {
        font-size: 65px;
        color: #afafaf;
    }
    p {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        color: $black;
        margin-top: 10px;
    }
    .btn {
        border-radius: 45px;
        border: 1px solid #ff136f;
        padding: 10px 25px;
        color: #ff136f;
        &:hover {
            background-color: #ff136f;
            color: #fff;
        }
    }
}
