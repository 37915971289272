.save-btn-student-profile {
    background: #92559d;
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: #ffffff;
    outline: none;
    border: none;
    padding: 14px 55px;
}
