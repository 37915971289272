.custom-alert{
    padding: 0;
    .alert{
        padding: 5px;
        font-style: normal;
        font-weight: 600;
        border-radius: 65px;
        box-shadow: 0px 5.64912px 14.1228px rgba(0, 0, 0, 0.1);
        p{
            margin-left: 20px !important;
            font-size: 16px;
            color: #fff;
            @media (max-width:800px) {
                font-size: 13px;
            }
        }
        a, button{
            border-radius: 52px;
            padding: 8px 20px;
            font-size: 16px;
            color: #fff;
            background-color: rgb(102, 102, 102);
        }
    }
    .success{
        font-family: Hind Siliguri;
          p{
            color: #0F0F0F;

          }  
        a,button{
            background: #FF136F;
           
        }
    }
    .info{
        background: #FF8845;
        a,button{
            color: #FF8845;
            background-color: #fff;
        }
    }
    .error{
        background: #F9271A;
        a,button{
            color: #F9271A;
            background-color: #fff;
        }
    }
}