.view-mark-distribution {
    p,
    h4 {
        font-size: 14px;
        font-family: Poppins;
    }
    p {
        color: $gray4;
        font-weight: 400;
        margin: 5px 0;
    }
    h4 {
        color: $black;
        font-weight: 500;
    }
    .editor-name {
        color: $primary3;
        font-weight: 600;
    }
}
