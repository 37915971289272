.batch-list-component {
    .course-list-table {
        .table-search-field {
            position: relative;
            flex-grow: 1;
            width: 100%;
            img {
                position: absolute;
                right: 15px;
                top: 13px;
            }
        }
        @media (max-width: 768px) {
            .table-search-field {
                img {
                    top: 18px;
                }
            }
        }
        .status-btn {
            font-size: 14px;
            font-weight: 400;
            display: inline-block;
            padding: 4px 10px;
            border-radius: 5px;
            min-width: 90px;
            text-align: center;
            text-transform: capitalize;
            &.published {
                background-color: #dcffec;
                color: $success;
            }
            &.draft {
                background-color: #ffd8e7;
                color: $secondary;
            }
        }
    }
}
