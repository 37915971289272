.view-base-course-table {
    width: 90%;
    margin: 20px auto;
    td,
    th {
        padding: 10px 5px;
        font-family: Poppins;
    }
    th {
        width: 40%;
        vertical-align: top;
        font-weight: 500;
    }
    td {
        color: $gray4;
        width: 60%;
    }
    .course-code {
        background-color: #ffd8e7;
        color: $secondary;
        padding: 5px 20px;
        border-radius: 26px;
    }
    .editor-name {
        font-weight: 600;
        color: $primary3;
    }
}
.base-course-component {
}
