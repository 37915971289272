.bookmark-page {
  background-color: #f6f6f6;
  .page-header {
    margin-bottom: -30px;
    z-index: 0;
  }
  .search-area {
    z-index: 1;

    .search-box {
      background: #faf7ff;
      padding: 12px 15px;
      border-radius: 10px;
      position: relative;

      &:hover {
        .search-btn {
          right: 100px;
          display: none;
        }
      }
      .search-input {
        *:focus {
          outline: none;
          box-shadow: none;
        }
        .rbt-input-multi.form-control.rbt-input.focus {
          outline: none !important;
          box-shadow: none !important;
          border: none !important;
        }
        background: #ffffff;
        border-radius: 10px;
        border-color: transparent;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        select {
          border: 0;
          margin: 10px;
          padding: 0 15px;
          border-left: 1px solid #d6d6d6;
        }
        .rbt {
          width: 100%;
        }

        .rbt-menu {
          margin-bottom: 2px;
          border-radius: 5px;
          border: 1px solid #f5f5f5;
          margin-top: 10px;
        }
        .rbt-token {
          background: #f1f1f1;
          border-radius: 5px;
          color: #333333;
          font-size: 12px;
          padding-top: 7px;
          padding-bottom: 7px;
          word-break: break-all;
          margin-right: 5px;
          .rbt-close {
            margin-right: 4px;
            width: 12px;
            height: 12px;
            background: #adadad;
            border-radius: 50%;
            color: #fff;
            text-align: center;
            padding: 0px;
            margin-top: 8px;
            font-size: 13px;
            padding-top: 2px;
            line-height: 0;
          }
        }
        .rbt-menu > .dropdown-item {
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          /* width: auto; */
          display: block;
          &:hover {
            background: $primary2;
            color: #fff;
          }
        }

        .form-control {
          background: #ffffff;
          border-radius: 10px;
          border-color: transparent;
          height: 50px;
        }
        .rbt-input-multi.form-control {
          height: unset;
          flex: 1;
        }
      }
    }
  }
  .MuiPaginationItem-outlined {
    border: 1px solid #e8e8e8;
    color: #25282b;
  }
  .single-bookmark {
    background: #ffffff;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    align-items: baseline;
    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      /* identical to box height */
      margin-top: 0;

      color: #1e1e1e;
    }
    img {
      width: 25px !important;
      height: 25px !important;
      margin-top: 3px;
      display: block;
    }
    .tag {
      background: #faf7ff;
      border-radius: 25px;
      margin: 5px;
      color: #858585;
      font-size: 12px;
    }
    .time {
      color: #a2a2a2;
      font-size: 15px;
      margin-right: 30px;
    }
    .go-link-btn {
      border: 1px solid #858585;
      box-sizing: border-box;
      border-radius: 25px;
      width: 98px;
      &:hover {
        background-color: #f5146f;
        border-color: #f5146f;
        color: #fff;
      }
    }

    .btn::after {
      all: unset;
    }
    .actions {
      button {
        outline: 0;
      }
      svg {
        font-size: 18px;
      }
      color: #858585;
      margin-bottom: 10px;
    }
  }
}
