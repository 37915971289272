.course-overview-skeleton{
    .skeleton-btn{
        border-radius: 35px;
    }
    .skeleton-thumb{
        border-radius: 10px;
    }
    .skeleton-status{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .skeleton-icon{
        border-radius: 12px;
    }
    .skeleton-count{
        border-radius: 57px;
        flex: .33;
        margin-right: 2px;
    }
    .skeleton-topic{
        height: 14px;
        width: 100%;
        display: block;
        margin-bottom: 10px;
    }
    .skeleton-topic.small{
        width: 80%;
    }
    .skeleton-title{
        width: 90%;
    }
    .skeleton-title.small{
        width: 70%;
    }
    .skeleton-comment{
        width: 100%;
        height: 14px;
    }
    .skeleton-comment.small{
        width: 75%;
    }
    .skeleton-bar{
        width: 50%;
    }
    .skeleton-bar.small{
        width: 40%;
    }
    .skeleton-sub-title{
        width: 70%;
    }
    .skeleton-sub-title.small{
        width: 55%;
    }
}