.shaka-player-component {
  .shaka-bottom-controls {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 8px;
  }
  .watermark {
    position: absolute;
    top: 10px;
    right: 10px;
    color: red;
    font-size: 15px;
    animation-name: example;
    animation-duration: 50s;
    animation-iteration-count: infinite;
  }
  @keyframes example {
    0%,
    5% {
      left: 20%;
      top: 20%;
      opacity: 0.8;
    }
    10%,
    20% {
      opacity: 0;
    }
    20%,
    30% {
      left: 80%;
      top: 20%;
      opacity: 0.8;
    }
    35%,
    45% {
      opacity: 0;
    }
    50%,
    55% {
      left: 50%;
      top: 50%;
      opacity: 0.8;
    }
    60%,
    70% {
      opacity: 0;
    }

    75%,
    80% {
      left: 80%;
      top: 60%;
      opacity: 0.8;
    }

    85%,
    90% {
      opacity: 0;
    }

    95%,
    100% {
      left: 20%;
      top: 70%;
      opacity: 0.8;
    }
  }
}
