.student-profile-navigation {
    p {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #353536;
    }
    .navigation-wrapper {
        min-height: 60vh;
        background: $white;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        .profile-circle {
            display: inline-block;
            position: relative;
            .verified-icon {
                color: $green;
                font-size: 25px;
                border-radius: 50px;
                border: 2px solid $white;
                bottom: -2px;
                right: -5px;
                position: absolute;
                background: $white;
                display: none;
            }
        }
        .profile-photo {
            height: 64px;
            width: 64px;
        }

        .nav-pills {
            padding-top: 34px;
        }
    }

    .nav {
        .nav-link,
        a {
            margin: 4px 0;
            color: #263238 !important;
            padding: 15px 24px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-decoration: none;
        }
        .active {
            background: linear-gradient(91.84deg, #f8f6f6 -32.23%, rgba(234, 234, 234, 0) 111.42%);
            filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.05));
            color: #263238 !important;
            border-radius: 1px;
            &::before {
                height: 56px;
                content: " ";
                position: absolute;
                top: 0;
                width: 7px;
                left: 0;
                background-color: #5b05df;
            }
        }
        svg {
            margin-right: 15px;
            font-size: 1.3em;
        }

        img {
            width: 24px;
            height: 24px;
        }
        span {
            margin-left: 12px;
        }
    }
}
