.custom-countdown-component {
    display: flex;
    justify-content: center;
    .each-digit {
        height: 65px;
        width: 52px;
        background: linear-gradient(180deg, #343c97 0%, #545ae8 100%);
        color: white;
        font-size: 36px;
        margin-left: 2px;
        margin-right: 2px;
        border-radius: 10px;
        text-align: center;
        margin-bottom: 10px;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        transform: perspective(1px) translateZ(0px);
        &::before {
            height: 50%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            z-index: -1;
            background: linear-gradient(180deg, #545ae8 18.46%, #353d98 232.31%);
        }
    }
    .digit-label {
        color: #1e1e1e;
        font-weight: 500;
        font-family: Poppins;
        font-size: 16px;
    }
    .row {
        margin-left: unset !important;
        margin-right: unset !important;
    }
    .countdown-helper-text {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #1e1e1e;
        margin: 30px;
    }
}
