.shaka-player-aleart-component {
  font-family: Fira Sans;
  font-style: normal;
  padding: 25px 120px;
  @media screen and (max-width: 768px) {
    padding: 5px 10px;
  }
  .header {
    h3 {
      font-weight: 700;
      font-size: 36px;
      color: #ff5450;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      color: #fff;
    }
  }
  .body {
    p {
      font-weight: 400;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.8);
    }
  }
  button {
    font-weight: 600;
    font-size: 18px;
    background-color: #583cea;
    padding: 10px 25px;
  }
}
