.contact-popup{
    z-index: 9999999999999999;
    position: fixed;
    bottom: 5px;
    right: 10px;
    .icon{
        position: absolute;
        right: 0;
        bottom: 10px;
        cursor: pointer;
    }
    .popup-logo{
        width: 200px;
        height: auto;
    }
    .icon img{
        transform: scale(.95);
    }
    .body{
        background-color: #fff;
        margin-bottom: 90px;
        padding:20px;
        background-color: #fff;
        border-radius: 18px;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .15);
        max-width: 100%;
        width: 340px;
        ul{
            margin-top: 5px;
            margin-bottom: 10px;
            display: flex;
            flex-wrap: wrap;
        }
        li{
            font-size: 16px;
            line-height: 24px;
            color: #684EFB;
            a{
                color: #684EFB;
                font-weight: 600;

            }
            span{
                margin-right: 8px;
                display: inline-block;
            }
        }
        h4{
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
        }

        .header{
            .close{
                border-radius: 50%;
                background-color: #d2d2d2;
                width: 26px;
                height: 26px;
                text-align: center;
                padding: 5px;
                cursor: pointer;
                position: absolute;
                top: 10px;
                right: 10px;
                font-size: 15px;
            }
        }
        p{
            margin: 0;
            font-size: 15px;
        }
        .help-tag{
            font-family: Hind Siliguri;
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            margin-top: 10px;
            color: #000000;
        }
    }

    @media (max-width: 777px) {
        bottom: 0;
        left: 5px;
        right: 5px;
        .icon img{
            transform: scale(.8);
        }
        ul{
            margin-top:0;
            margin-bottom: 5px;
            li{
                line-height: 22px;
            }
        }
        .popup-logo{
            width: 160px;
        }
        .icon{
            display: none;
        }
        .icon-alt{
            position: absolute;
            right: 0;
            bottom: 4px;
        }
        .body{
            width: 100%;
            margin-bottom: 4px;
            padding:10px 20px;
        }
        .help-tag{
            line-height: 29;
        }
        
    }
}