.dashboard-two-page {
    & .sales-compare-select {
        & .multiselect-container {
            width: fit-content;
            min-width: 200px;
            margin-bottom: 80px;

            & .search-wrapper {
                padding: 8px;

                & input {
                    max-width: 60px;
                }

                & ._7ahQImyV4dj0EpcNOjnwA {
                    background: $white;
                    color: $gray;
                    border: 1px solid #D6D6D6;
                    border-radius: 41px;
                    margin: 5px;

                    .icon_cancel {
                        width: 20px;
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    .total-amount-section {
        h2 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            color: #0d0f1d;
        }

        span {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color:#6e6b7b;
        }
    }

    .total-sale-graph {
        .sale-graph {
            position: relative;
        }

        .sale-label {
            position: absolute;
            left: 34%;
            top: 56%;
        }
    }

    @media screen and (max-width:1240px) and (min-width:768px) {
        .total-sale-graph {
            .sale-label-text {
                font-size: 12px !important;
            }

        }
    }

    .batchId-btn {
        background: #efefff;
        border-radius: 38px;
        color: #2508ff;
        border: none;
        outline: none;
        cursor: auto
    }
}