.add-job{
    background: #FFFFFF;
    height: 500px;
    padding: 15px;
    border-radius: 10px;
    .next-btn{
        background: #5E24F9;
        color: #FFFFFF;
    }
    button{
        width: 116px;
        height: 45px;
        border-radius: 5px;
        font-weight: 600;
    }
}