.faculty-single-item {
    border: 1px solid #e7e7e7;
    padding: 25px;
    margin-block: 12px;
    border-radius: 8px;
    transition: 0.3s ease-in;
    .image {
        width: 250px;
        height: 250px;
        margin-right: 24px;
        object-fit: cover;
        border-radius: 8px;
        background-color: #e4e4e4;
        @media (max-width: 1200px) {
            width: 170px;
            height: 170px;
        }
    }
    .name {
        font-weight: 700;
        font-size: 25px;
        color: $dark02;
        margin: 20px 0;
    }
    .quote {
        font-family: "ruposhi-bangla";
        font-size: 22px;
        color: $dark02;
        .see-more-btn {
            cursor: pointer;
            color: $violet03;
            font-weight: 600;
            white-space: nowrap;
            font-size: 18px;
        }
        @media (max-width: 768px) {
            font-size: 20px;
        }
    }
    &:hover {
        background-color: $dark07;
    }
    @media (max-width: 768px) {
        text-align: center;
        align-items: center;
        .image {
            margin-right: 0;
        }
    }
}
