.module-component {
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px #0000000d;
    border-radius: 4px;
}
.milestone-component {
    .milestone-title {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 18px !important;
        color: #1c2729;
    }
    .MuiAccordionSummary-root {
        padding: 0 13px;
    }
    .MuiAccordionSummary-content.Mui-expanded {
        margin: 10px 0 0 0;
    }
    .MuiAccordionSummary-content {
        margin-bottom: 0;
    }
    .MuiAccordionSummary-expandIcon.Mui-expanded {
        transform: rotate(360deg);
    }
}
.milestone-component,
.module-component {
    .module-title {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        color: #1c2729;
    }
    .status-icon {
        height: 15px;
        width: 15px;
        margin-left: 9px;
    }
    .status-icon-lock {
        height: 20px;
        width: 20px;
        margin-left: 7px;
    }

    .video-play {
        background: #fff;
        border-left: 8px solid $primary2;
        text-align: left;
        border-radius: 3px;
        color: #000000bf !important;
    }
    .sub-milestone-title {
        font-size: 12px;
        color: #a2a2a2;
        margin-top: 5px;
        font-weight: 400;
    }
    button {
        white-space: break-spaces !important;
    }
    .unit-component {
        .single-module {
            padding: 10px 0;
            margin-left: 15px;
        }
        .unActive-title {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            color: #00000080;
        }
        .active-title {
            font-weight: 600;
            color: #000000bf;
            font-size: 18px;
            // padding: 0;
            // margin: 0;
        }
        @media (max-width: 768px) {
            button,
            p,
            h5 {
                font-size: 13px;
                line-height: 20px;
            }
        }
    }
    .play-button-icon {
        height: 18px;
        width: 18px;
    }

    @media only screen and (max-width: 425px) {
        .module-title {
            font-size: 14px;
        }
    }
}
.module-component {
    .MuiAccordionSummary-expandIcon.Mui-expanded {
        transform: rotate(180deg);
    }
}
