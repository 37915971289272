.un-published-page {
    .card {
        padding: 40px 0px;
        background: $white;
        box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        h4 {
            font-family: Poppins;
            font-weight: 600;
            font-size: 16px;
            padding-top: 20px;
            color: #535353;
        }
    }
}
