.create-semester-component {
    .MuiGrid-root.MuiGrid-item {
        padding-bottom: 0;
    }
}
.create-semester-form {
    padding: 0 100px;
}
@media (max-width: 768px) {
    .create-semester-form {
        padding: 0 20px;
    }
}
.view-semester-component {
    padding: 30px 50px;
    .semester-card {
        border-radius: 8px;
        p {
            font-size: 14px;
            color: $gray4;
            font-family: Poppins;
            margin: 10px 0;
        }
        h4 {
            font-size: 20px;
            color: #0d0f1d;
        }
    }
    .semester-list-items {
        h4,
        p {
            padding: 10px;
            font-size: 14px;
            font-family: Poppins;
            margin: 0 !important;
        }
        h4 {
            color: #000;
            font-weight: 500;
        }
        p {
            color: $gray4;
            font-weight: 400;
        }
    }
    .semester-list-table {
        width: 100%;
        th,
        td {
            padding: 10px;
            font-size: 14px;
            font-family: Poppins;
        }
        th {
            color: #000;
            font-weight: 500;
            min-width: 200px;
        }
        td {
            color: $gray4;
            font-weight: 400;
        }
        &.bottom {
            th {
                // width: 10%;
                min-width: 80px;
            }
            td {
                .course-code {
                    display: inline-block;
                    border-radius: 22px;
                    padding: 5px 20px;
                    margin: 5px;
                    font-size: 12px;
                }
                .red {
                    color: #ff4b91;
                    background-color: #ffd8e7;
                }
                .blue {
                    color: $primary2;
                    background-color: #ecf3ff;
                }
                .green {
                    color: #54cf8d;
                    background-color: #dcffec;
                }
                .yellow {
                    color: #ffd019;
                    background-color: #fffae8;
                }
                .editor {
                    color: $primary3;
                    font-weight: 600;
                }
            }
        }
    }
}
