.next-step-page{
    background-color: #fff !important;
    p{
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        align-items: center;
        color: #323232;
    }
    
    ul{
        border-left: 3px dashed rgb(197, 196, 196);
        padding-left: 35px;
        
        li{
            display: flex;
            font-family: Hind Siliguri;
            font-weight: bold;
            font-size: 16px;
            line-height: 23px;
            align-items: center;
            color: #323232;
            margin-bottom: 30px;
            .number{
                display: block;
                border-radius: 50%;
                width: 55px;
                height: 55px;
                background: linear-gradient(180deg, #684EFB 0.01%, #5A00DD 100.01%);
                color: #fff;
                padding: 16px;
                font-size: 1.5rem;
                padding-top: 12px;
                position: relative;
                left: -29px;
                border: 5px solid #fff;
            }
            
        }
    }
}