.course-information-card {
    box-shadow: 0px 30px 80px 0px #3754aa1a ;
    border-radius: 16px;
    min-height: 272px;
}
.course-learning-card{
    box-shadow: 0px 30px 80px 0px #3754aa1a ;
    border-radius: 16px;
    .objective-image {
        height: 107px;
        width: 107px;
        margin-right: 15px;
    }
}