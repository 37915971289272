.assignment-submission-component {
    .assignment-textarea {
        background: #f7f7f7;
        border: 1px solid #b5b5b5;
        box-sizing: border-box;
        border-radius: 10px;
    }

    .description-title {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #000;
    }

    .submit-button {
        background-color: $mid-light-grey;
        border-radius: 43px;
        height: 42px;
        width: 128px;
        color: $black;
        margin-left: 5px;
    }
    @media (max-width: 768px) {
        .description-title {
            font-size: 14px;
        }
    }
}
