.course-outline-table {
    font-size: 18px;
    @media (max-width: 1000px) {
        font-size: 14px;
    }
    @media (max-width: 768px) {
        font-size: 12px;
    }
    width: 100%;

    font-family: "Fira Sans";
    td {
        border: 1px solid rgba(18, 17, 19, 0.1);
    }
    thead {
        background: rgba(88, 60, 234, 0.1);
        th {
            font-weight: 600;
            font-size: 1.3em;
            color: #583cea;
            padding: 1.8em 1em;
            text-align: center;
            @media (max-width: 600px) {
                font-size: 1.16em;
            }
            &:first-child {
                border-top-left-radius: 12px;
            }
            &:last-child {
                border-top-right-radius: 12px;
            }
        }
        .module-title {
            text-align: left;
            padding-left: 1.5em;
        }
    }
    tbody {
        td {
            text-align: center;
            padding: 1.1em 0.9em;
            font-size: 1em;
            color: #1e1e1eb2;
        }
        .week-name {
            background: linear-gradient(0deg, rgba(88, 60, 234, 0.05) 0%, rgba(88, 60, 234, 0) 100%);
            font-weight: 600;
            font-size: 2.2em;
            color: #583cea;
            border-bottom: 1px solid #583cea;
            text-align: center;
            @media (max-width: 600px) {
                font-size: 1.16em;
            }
        }
        .no-module {
            td {
                background: #1e1e1e1a;
            }
        }
        .module-name {
            text-align: left;
            padding-left: 1.5em;
        }
    }
}
