.success-student-skeleton{
    
    .skeleton-thumbnail{
        border-radius: 0;
        height: 232px;
    }
    .skeleton-title.title,
    .skeleton-title.sub-title{
        left: 50%;
        @media (max-width: 775px) {
            left: 0;
        }
    }
    .card{
        border-radius: 0 !important;
    }
    .card-body{
        overflow: hidden;
    }
   
}
